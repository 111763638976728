import React, {useState, useEffect} from 'react';
import Header from "./Header";
import {fetchAllEntries, addNewEntry, deleteEntryById} from '../utils/kv_api';

function BPanel() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sortOrder, setSortOrder] = useState('↑');
    const [showPending, setShowPending] = useState(true);
    const [showCompleted, setShowCompleted] = useState(false);
    const [showNotFound, setShowNotFound] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        fetchAllEntries()
            .then(jsonData => {
                setData(jsonData);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoading(false);
            });
    }, []);

    // New function to handle sort order change
    const handleSortOrderChange = () => {
        const newData = [...data].reverse();
        setData(newData);
        setSortOrder(sortOrder === '↑' ? '↓' : '↑');
    };

    const handleShowPendingChange = (event) => {
        setShowPending(event.target.checked);
    };

    // New function to handle completed filter change
    const handleShowCompletedChange = (event) => {
        setShowCompleted(event.target.checked);
    };

    // New function to handle not found filter change
    const handleShowNotFoundChange = (event) => {
        setShowNotFound(event.target.checked);
    };

    const handleStatusChange = (id, newStatus) => {
        const entry = data.find(entry => entry._id === id);
        if (entry) {
            const updatedEntry = {...entry, state: newStatus};
            console.log(updatedEntry); // Log the updated entry
            addNewEntry(updatedEntry)
                .then(updatedEntry => {
                    fetchAllEntries()
                        .then(jsonData => {
                            setData(jsonData);
                            setIsLoading(false);
                        })
                        .catch(error => {
                            console.error('Error:', error);
                            setIsLoading(false);
                        });
                })
                .catch(error => console.error('Error:', error));
        } else {
            console.error(`Error: No entry found with id ${id}`);
        }
    };

    const handleDeleteEntry = (id) => {
        deleteEntryById(id)
            .then(() => {
                if (data) {
                    setData(data.filter(entry => entry._id !== id));
                }
            })
            .catch(error => console.error('Error:', error));
    };

    return (<div className="w-full overflow-auto">
        <div className="flex items-center justify-center w-full">
            <div className="bg-dark-accent p-2 m-3 rounded-lg flex items-center w-full justify-between">
                <Header
                    showPending={showPending}
                    handleShowPendingChange={handleShowPendingChange}
                    showCompleted={showCompleted}
                    handleShowCompletedChange={handleShowCompletedChange}
                    showNotFound={showNotFound}
                    handleShowNotFoundChange={handleShowNotFoundChange}
                />
            </div>
        </div>

        <div className="container flex flex-col w-full">
            <div className="flex justify-between items-center my-4">
                <button onClick={handleSortOrderChange}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Fecha {sortOrder}
                </button>
            </div>
            {isLoading ? (<div>Loading...</div>  // Replace this with your spinner component
            ) : (data.filter(item => (showCompleted && item.state === 'completed') || (showNotFound && item.state === 'not_found') || (showPending && item.state === 'pending')).map((item) => (
                <div key={item._id} className="bg-dark-accent shadow overflow-hidden sm:rounded-lg mb-4" style={{
                    backgroundColor: item.state === 'completed' ? 'rgba(144, 238, 144, 0.1)' : item.state === 'not_found' ? 'rgba(255, 0, 0, 0.1)' : 'dark-accent'
                }}>
                    <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                        <div className="flex flex-col">
                            <a href={item.link}
                               className="text-lg leading-6 font-medium text-gray-200 mb-2">{item.title}</a>
                            {item.username && <p className="text-sm text-gray-400">Requested by {item.username}</p>}
                        </div>
                        <div>
                            <select onChange={(e) => handleStatusChange(item._id, e.target.value)}
                                    value={item.state}
                                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                <option value="pending">Pendiente</option>
                                <option value="completed">Completado</option>
                                <option value="not_found">No encontrado</option>
                            </select>
                            <button onClick={() => handleDeleteEntry(item._id)}
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                Borrar
                            </button>
                        </div>
                    </div>
                </div>)))}
        </div>
    </div>);
}

export default BPanel;