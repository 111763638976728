import React from 'react';
import Logo from '../images/plexticiones-logo.svg';

function Header({showPending, handleShowPendingChange, showCompleted, handleShowCompletedChange, showNotFound, handleShowNotFoundChange}) {

    return (<div className="py-2 mx-10 w-full">
        <div className="md:flex md:justify-between md:items-center text-center md:text-left">
            <a href="/">
                <img src={Logo} alt="Plexticiones Logo" className="logo text-lg text-white md:mr-auto"/>
            </a>
            <div className="mt-2 md:mt-0 md:flex md:gap-4 md:justify-end mx-auto md:mx-0">
                <label className="inline-flex items-center">
                    <input type="checkbox" checked={showPending} onChange={handleShowPendingChange}
                           className="form-checkbox h-5 w-5 text-white"/>
                    <span className="ml-2 text-gray-700">Show pending</span>
                </label>
                <label className="inline-flex items-center">
                    <input type="checkbox" checked={showCompleted} onChange={handleShowCompletedChange}
                           className="form-checkbox h-5 w-5 text-white"/>
                    <span className="ml-2 text-gray-700">Show completed</span>
                </label>
                <label className="inline-flex items-center">
                    <input type="checkbox" checked={showNotFound} onChange={handleShowNotFoundChange}
                           className="form-checkbox h-5 w-5 text-white"/>
                    <span className="ml-2 text-gray-700">Show not found</span>
                </label>
            </div>
        </div>
    </div>);
}

export default Header;