import config from "../config";

const KV_REST_API_TOKEN = config.KV_API_TOKEN;
const KV_REST_API_URL = config.KV_API_URL;


// Generic function to fetch data from the API
async function fetchData(url, options = {}) {
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${KV_REST_API_TOKEN}`,
            'Content-Type': 'application/json',
            ...options.headers,
        },
        ...options,
    });

    if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    if (data.result === "OK") {
        return data.result;
    } else {
        return data.result ? JSON.parse(data.result) : null;
    }
}

async function fetchAllEntries() {
    // Fetch the ID list
    const idListResponse = await fetch(`${KV_REST_API_URL}/get/id-list`, {
        headers: {
            Authorization: `Bearer ${KV_REST_API_TOKEN}`
        }
    });
    let idListData = await idListResponse.json();
    let idList = idListData.result ? JSON.parse(idListData.result) : [];

    // Fetch and return the information for each ID
    const entries = [];
    for (const id of idList) {
        const response = await fetch(`${KV_REST_API_URL}/get/${id}`, {
            headers: {
                Authorization: `Bearer ${KV_REST_API_TOKEN}`
            }
        });
        const data = await response.json();
        entries.push(JSON.parse(data.result));
    }
    return entries;
}

async function addNewEntry(newEntry) {
    // Fetch the existing ID list
    const idListResponse = await fetch(`${KV_REST_API_URL}/get/id-list`, {
        headers: {
            Authorization: `Bearer ${KV_REST_API_TOKEN}`
        }
    });
    let idListData = await idListResponse.json();
    let idList = idListData.result ? JSON.parse(idListData.result) : [];

    // Check if the new entry's ID is already in the list
    if (!idList.includes(newEntry._id)) {
        // Add the new entry's ID to the list
        idList.push(newEntry._id);

        // Store the updated ID list
        await fetch(`${KV_REST_API_URL}/set/id-list`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${KV_REST_API_TOKEN}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(idList),
        });
    }

    // Store the new entry under its ID (this will overwrite the entry if it already exists)
    await fetch(`${KV_REST_API_URL}/set/${newEntry._id}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${KV_REST_API_TOKEN}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(newEntry),
    });
}

async function fetchEntryStateById(id) {
    // Fetch the entry by its ID
    const response = await fetch(`${KV_REST_API_URL}/get/${id}`, {
        headers: {
            Authorization: `Bearer ${KV_REST_API_TOKEN}`
        }
    });
    const data = await response.json();
    const entry = JSON.parse(data.result);

    // Return the state of the entry if it exists, otherwise return null
    return entry ? entry.state : null;
}

async function fetchEntryById(id) {
    // Fetch the entry by its ID
    const response = await fetch(`${KV_REST_API_URL}/get/${id}`, {
        headers: {
            Authorization: `Bearer ${KV_REST_API_TOKEN}`
        }
    });
    const data = await response.json();
    return JSON.parse(data.result);
}

async function deleteEntryById(id) {
    // Fetch the existing ID list
    const idListResponse = await fetch(`${KV_REST_API_URL}/get/id-list`, {
        headers: {
            Authorization: `Bearer ${KV_REST_API_TOKEN}`
        }
    });
    let idListData = await idListResponse.json();
    let idList = idListData.result ? JSON.parse(idListData.result) : [];

    // Remove the entry's ID from the list
    if (idList) {
        idList = idList.filter(entryId => entryId !== id);
    }

    // Store the updated ID list
    await fetch(`${KV_REST_API_URL}/set/id-list`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${KV_REST_API_TOKEN}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(idList),
    });

    // Delete the entry
    await fetch(`${KV_REST_API_URL}/del/${id}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${KV_REST_API_TOKEN}`
        }
    });
}

export {addNewEntry, fetchEntryStateById, fetchAllEntries, fetchEntryById, deleteEntryById, fetchData };