import BPanel from "./components/BPanel";

function App() {
  return (
    <div className="App">
        <BPanel />
    </div>
  );
}

export default App;
